import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ContentsHeader from '~/components/molecules/ContentsHeader';
import ContentsSettingList from '~/components/organisms/contentsSetting/List';
import Submit from '~/components/organisms/contentsSetting/Submit';
import { RootReducerState } from '~/modules';
import { contentsSettingAction } from '~/modules/ContentsSettingModule';

interface ReducerData {
    enabled: RootReducerState['manageFCMReducer']['enabled'];
    deviceID: RootReducerState['manageFCMReducer']['data']['deviceID'];
}

const ContentsSetting = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { enabled, deviceID } = useSelector<RootReducerState, ReducerData>(
        ({ manageFCMReducer }) => ({
            enabled: manageFCMReducer.enabled,
            deviceID: manageFCMReducer.data.deviceID
        })
    );

    // 콘텐츠 설정 조회
    useEffect(() => {
        if (deviceID === null) return;
        dispatch(contentsSettingAction.fetchData(deviceID));
    }, [dispatch, deviceID]);

    // push 알림 변경 시 push 설정 초기화
    useEffect(() => {
        dispatch(contentsSettingAction.resetPushSetting());
    }, [dispatch, enabled]);

    return (
        <div className="alarmContsSet">
            <div className="head">
                {/* 알림 콘텐츠 설정 안내 문구 */}
                <ContentsHeader
                    title={t('알림 콘텐츠 설정')}
                    descriptions={[
                        t('콘텐츠 종류별로 알림 수신 여부를 설정합니다.'),
                        t(
                            '설정 변경 또는 초기화 시, 반드시 저장을 해야 합니다.'
                        )
                    ]}
                />

                {/* 저장 및 초기화 버튼 */}
                <Submit />
            </div>

            {/* 알림 콘텐츠 설정 리스트 */}
            <ContentsSettingList />
        </div>
    );
};

export default ContentsSetting;
