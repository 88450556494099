import classnames from 'classnames';
import React, {
    forwardRef,
    ForwardRefRenderFunction,
    InputHTMLAttributes,
    useState
} from 'react';
import { useId } from 'react-id-generator';

interface CheckBoxProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'checked'> {
    checked?: boolean;
}

const ControlledCheckBox: ForwardRefRenderFunction<
    HTMLInputElement,
    CheckBoxProps
> = ({ id, className = false, children, ...props }, ref) => {
    const [uniqId] = useId(1, 'checkbox-');

    const checkboxId = id || uniqId;

    return (
        <div className={classnames(['checkbox', className])}>
            <input type="checkbox" {...props} id={checkboxId} ref={ref} />
            <label htmlFor={checkboxId}>{children}</label>
        </div>
    );
};

const ForwardedControlledCheckBox = forwardRef(ControlledCheckBox);

const UncontrolledCheckBox: ForwardRefRenderFunction<
    HTMLInputElement,
    CheckBoxProps
> = ({ onChange, defaultChecked, ...props }, ref) => {
    const [checked, setChecked] = useState(defaultChecked);

    const handleChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (
        event
    ) => {
        setChecked(event.currentTarget.checked);
        onChange?.(event);
    };

    return (
        <ForwardedControlledCheckBox
            {...props}
            checked={checked}
            onChange={handleChange}
            ref={ref}
        />
    );
};

const ForwardedUncontrolledCheckBox = forwardRef(UncontrolledCheckBox);

const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(function CheckBox(
    { checked, ...props },
    ref
) {
    if (checked !== undefined) {
        return (
            <ForwardedControlledCheckBox
                {...props}
                checked={checked}
                ref={ref}
            />
        );
    } else {
        return <ForwardedUncontrolledCheckBox {...props} ref={ref} />;
    }
});

export default CheckBox;
