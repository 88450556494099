import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import LoadingSpinner from '~/components/atoms/LoadingSpinner';
import ContentsSettingSection from '~/components/organisms/contentsSetting/Section';
import Status from '~/constants/Status';
import { RootReducerState } from '~/modules';

const ContentsSettingList: FC = () => {
    const { status, data } = useSelector<
        RootReducerState,
        RootReducerState['contentsSettingReducer']
    >(({ contentsSettingReducer }) => contentsSettingReducer);

    return (
        <div className="settingList">
            {/* 로딩 ui */}
            {status !== Status.SUCCESS && <LoadingSpinner />}

            {/* 리스트 노출 */}
            {status === Status.SUCCESS &&
                data?.info.map((group, index) => (
                    <ContentsSettingSection
                        key={`group-${index}`}
                        group={group}
                        index={index}
                    />
                ))}
        </div>
    );
};

export default ContentsSettingList;
