import React, { FC, HTMLAttributes } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootReducerState } from '~/modules';
import { contentsSettingAction } from '~/modules/ContentsSettingModule';

type ChangedDataType = RootReducerState['contentsSettingReducer']['changed'];

type HandleClickButtonType = HTMLAttributes<HTMLButtonElement>['onClick'];

const Submit: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const changed = useSelector<RootReducerState, ChangedDataType>(
        ({ contentsSettingReducer }) => contentsSettingReducer.changed
    );

    // 설정 초기화
    const handleResetSetting: HandleClickButtonType = () => {
        if (
            !confirm(
                t(
                    `변경했던 모든 세부 설정값을 초기 상태로 되돌립니다.\n초기화한 알림 설정은 저장 시 적용됩니다.`
                )
            )
        )
            return;

        dispatch(contentsSettingAction.resetSetting());
    };

    // 설정 저장
    const handleSaveSetting: HandleClickButtonType = () => {
        dispatch(contentsSettingAction.updateSetting());
    };

    return (
        <div className="btns">
            <button
                type="button"
                className="reset"
                onClick={handleResetSetting}>
                <Trans>초기화</Trans>
            </button>
            <button
                type="button"
                disabled={!changed}
                className="save"
                onClick={handleSaveSetting}>
                <Trans>저장</Trans>
            </button>
        </div>
    );
};

export default Submit;
