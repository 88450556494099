import React from 'react';
import { useTranslation } from 'react-i18next';

import Anchor from '~/components/atoms/Anchor';
import ContentsHeader from '~/components/molecules/ContentsHeader';
import SettingArea from '~/components/organisms/notificationSetting/SettingArea';
import { BJ_HOST } from '~/constants/hosts';
import Locale from '~/constants/Locale';
import NotificationPermission from '~/constants/NotificationPermission';
import getUserLocale from '~/libs/getUserLocale';
import useFCMAllow from '~/libs/hooks/useFCMAllow';

const NotificationSetting = () => {
    const { t } = useTranslation();

    const [, allowData] = useFCMAllow();

    const locale = getUserLocale();

    // 권한 및 지원 여부 체크
    if (
        !allowData ||
        !allowData.support ||
        allowData.permission === NotificationPermission.DENIED
    ) {
        return null;
    }

    return (
        <div className="deskAlarm">
            {/* 알림 설정 헤더 */}
            <ContentsHeader
                title={t('데스크톱 알림')}
                descriptions={[
                    t(
                        '아프리카TV를 사용 중이 아니여도 화면 모서리에서 푸시 알림을 받습니다.'
                    ),
                    t(
                        '웹 브라우저에서 아프리카TV의 알림 표시를 허용해야만 푸시 알림을 받을 수 있습니다.'
                    ),
                    t(
                        '데스크톱 알림은 윈도우10, 크롬 웹브라우저 환경에 최적화 되었습니다.'
                    )
                ]}
            />

            {/* 브라우저 푸시 알림 설정 */}
            <SettingArea />

            {/* 상세 안내 */}
            {locale === Locale.KO_KR && (
                <Anchor
                    href={`${BJ_HOST}/afsupport/post/81061416`}
                    target="_blank"
                    className="btn_detail">
                    자세히 알아보기
                </Anchor>
            )}
        </div>
    );
};

export default NotificationSetting;
