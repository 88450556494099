import classnames from 'classnames';
import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import ContentsSettingItem from '~/components/organisms/contentsSetting/Item';
import { RootReducerState } from '~/modules';
import { ContentsSettingGroup } from '~/types/response/ContentsSettingResponse';

interface ContentsSettingSectionProps {
    group: ContentsSettingGroup;
    index?: number;
}

const ContentsSettingSection: FC<ContentsSettingSectionProps> = ({
    group: { group_title: groupTitle, list },
    index
}) => {
    const enabled = useSelector<RootReducerState, boolean>(
        ({ manageFCMReducer }) => manageFCMReducer.enabled
    );

    // 설정 이름 노출 여부
    const showSettingName = index !== undefined && index === 0;

    return (
        <div className="section">
            {/* 그룹 명 */}
            <h4>{groupTitle}</h4>

            {/* 상단 설정 문구 */}
            {showSettingName && (
                <div className="setName">
                    <span className={classnames({ disabled: !enabled })}>
                        <Trans>푸시 설정</Trans>
                    </span>
                    <span>
                        <Trans>기록 설정</Trans>
                    </span>
                </div>
            )}

            {/* 알림 콘텐츠 설정 리스트 */}
            {list.map((info) => (
                <ContentsSettingItem key={info.key} info={info} />
            ))}
        </div>
    );
};

export default ContentsSettingSection;
