import React, { ComponentProps, FC, useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CheckBox from '~/components/atoms/CheckBox';
import Status from '~/constants/Status';
import detectBrowser from '~/libs/detectBrowser';
import { RootReducerState } from '~/modules';
import { manageFCMAction } from '~/modules/ManageFCMModule';

type PushSettingHandleType = ComponentProps<typeof CheckBox>['onChange'];

enum IconClassName {
    CHROME = 'chrome',
    FIREFOX = 'firefox',
    EDGE = 'edge',
    WHALE = 'whale',
    SAFARI = 'safari',
    OPERA = 'opera'
}

interface ReducerStates {
    enabled: RootReducerState['manageFCMReducer']['enabled'];
    status: RootReducerState['manageFCMReducer']['status'];
    allowStatus: RootReducerState['allowFCMReducer']['status'];
}

const SettingArea: FC = () => {
    const dispatch = useDispatch();

    const { enabled, status, allowStatus } = useSelector<
        RootReducerState,
        ReducerStates
    >(({ manageFCMReducer, allowFCMReducer }) => ({
        enabled: manageFCMReducer.enabled,
        status: manageFCMReducer.status,
        allowStatus: allowFCMReducer.status
    }));

    // push setting 변경 시 enabled 변경
    const handleChangePushSetting: PushSettingHandleType = useCallback(
        (event) => {
            if (status !== Status.SUCCESS || allowStatus !== Status.SUCCESS) {
                alert('설정 변경 중입니다. 잠시만 기다려주세요.');
                return;
            }

            dispatch(
                manageFCMAction.updateEnabledWithPermission(
                    event.currentTarget.checked
                )
            );
        },
        [dispatch, status, allowStatus]
    );

    // 브라우저 icon class name
    const className = useMemo(() => {
        const info = detectBrowser();
        if (!info) return IconClassName.CHROME;

        switch (info.name) {
            case 'firefox':
                return IconClassName.FIREFOX;

            case 'edge':
            case 'edge-chromium':
            case 'edge-ios':
                return IconClassName.EDGE;

            case 'whale':
                return IconClassName.WHALE;

            case 'safari':
                return IconClassName.SAFARI;

            case 'opera':
            case 'opera-mini':
                return IconClassName.OPERA;

            case 'chrome':
            default:
                return IconClassName.CHROME;
        }
    }, []);

    return (
        <div className="settingPush">
            <dl className={className}>
                <dt>
                    <Trans>이 브라우저에서 알림 받기</Trans>
                </dt>
                <dd>
                    <CheckBox
                        checked={enabled}
                        onChange={handleChangePushSetting}>
                        <Trans>설정/해제</Trans>
                        <i />
                    </CheckBox>
                </dd>
            </dl>
        </div>
    );
};

export default SettingArea;
