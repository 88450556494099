import React, {
    AnchorHTMLAttributes,
    forwardRef,
    ForwardRefRenderFunction
} from 'react';

interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {}

const Anchor: ForwardRefRenderFunction<HTMLAnchorElement, AnchorProps> = (
    { children, ...props },
    ref
) => (
    <a {...props} ref={ref}>
        {children}
    </a>
);

export default forwardRef(Anchor);
