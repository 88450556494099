import React, { ComponentProps, FC } from 'react';
import { Trans } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import CheckBox from '~/components/atoms/CheckBox';
import InfoCheckboxItem from '~/components/molecules/InfoCheckboxItem';
import ContentsSettingType from '~/constants/ContentsSettingType';
import { RootReducerState } from '~/modules';
import { contentsSettingAction } from '~/modules/ContentsSettingModule';
import { ContentsSettingItem as ContentsSettingItemData } from '~/types/response/ContentsSettingResponse';

type HandleChangeSettingType = (
    type: ContentsSettingType
) => ComponentProps<typeof CheckBox>['onChange'];

interface ContentsSettingData {
    push: boolean;
    noti: boolean;
}

interface ContentsSettingItemProps {
    info: ContentsSettingItemData;
}

const ContentsSettingItem: FC<ContentsSettingItemProps> = ({
    info: { key, title, sub_title: subTitle }
}) => {
    const dispatch = useDispatch();
    const enabled = useSelector<RootReducerState, boolean>(
        ({ manageFCMReducer }) => manageFCMReducer.enabled
    );

    // 현재 세팅 데이터 조회
    const { push, noti } = useSelector<RootReducerState, ContentsSettingData>(
        ({ contentsSettingReducer }) => {
            const setting = {
                push: false,
                noti: false
            };

            const { data } = contentsSettingReducer;
            if (!data) return setting;

            const { push, noti } = data;
            setting.push = push[key];
            setting.noti = noti[key];

            return setting;
        },
        shallowEqual
    );

    // 설정 변경
    const handleChangeSetting: HandleChangeSettingType = (type) => (event) => {
        dispatch(
            contentsSettingAction.changeSetting(
                type,
                key,
                event.currentTarget.checked
            )
        );
    };

    return (
        <InfoCheckboxItem title={title} subTitle={subTitle}>
            <CheckBox
                disabled={!enabled}
                checked={push}
                onChange={handleChangeSetting(ContentsSettingType.PUSH)}>
                <Trans>설정/해제</Trans>
                <i />
            </CheckBox>
            <CheckBox
                checked={noti}
                onChange={handleChangeSetting(ContentsSettingType.NOTI)}>
                <Trans>설정/해제</Trans>
                <i />
            </CheckBox>
        </InfoCheckboxItem>
    );
};

export default ContentsSettingItem;
