import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

interface LoadingSpinner
    extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {}

const LoadingSpinner: FC<LoadingSpinner> = ({ className }) => (
    <div className={classnames(['loading-spinner', className])}>
        <i />
        <i />
        <i />
        <i />
        <i />
        <i />
        <i />
        <i />
        <i />
        <i />
        <i />
        <i />
    </div>
);

export default LoadingSpinner;
