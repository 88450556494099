import React, { FC } from 'react';

interface ContentsHeaderProps {
    title: string;
    descriptions: string[];
}

const ContentsHeader: FC<ContentsHeaderProps> = ({ title, descriptions }) => (
    <>
        <h3>{title}</h3>
        <ul className="desc">
            {descriptions.map((description, index) => (
                <li key={index}>- {description}</li>
            ))}
        </ul>
    </>
);

export default ContentsHeader;
