import classnames from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

interface InfoCheckboxItem {
    title: string;
    subTitle: string;
    className?: HTMLAttributes<HTMLDivElement>['className'];
}

const InfoCheckboxItem: FC<PropsWithChildren<InfoCheckboxItem>> = ({
    title,
    subTitle,
    className,
    children
}) => {
    return (
        <div className={classnames(['item', className])}>
            <div className="th">
                <p>{title}</p>
                <span>{subTitle}</span>
            </div>
            <div className="btns">{children}</div>
        </div>
    );
};

export default InfoCheckboxItem;
