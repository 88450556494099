import { PageProps } from 'gatsby';
import React, { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SubPageLayout from '~/components/layouts/SubPageLayout';
import ContentsSetting from '~/components/organisms/contentsSetting';
import NotificationSetting from '~/components/organisms/notificationSetting';
import useFCMService from '~/libs/hooks/useFCMService';
import { RootReducerState } from '~/modules';

const SettingPage: FC<PageProps> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    type ManageFCMStatusType = RootReducerState['manageFCMReducer']['status'];
    const status = useSelector<RootReducerState, ManageFCMStatusType>(
        ({ manageFCMReducer }) => manageFCMReducer.status
    );

    // 알림 기능 실행
    useFCMService(true);

    const title = useMemo(() => `${t('알림 설정')} | ${t('아프리카TV')}`, [t]);

    return (
        <SubPageLayout>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className="alarmSetting">
                {/* 알림 설정 */}
                <NotificationSetting />

                {/* 알림 콘텐츠 설정 */}
                <ContentsSetting />
            </div>
        </SubPageLayout>
    );
};

export default SettingPage;
